import { DialogContentText } from "@mui/material";
import React, { Fragment } from "react";

const MesafeliSatisSozlesmesi = () => {
  return (
    <Fragment>
      <DialogContentText>
        <p>Son güncellenme: 22/12/2024</p>
        <h2>MESAFELİ SATIŞ SÖZLEŞMESİ</h2>
        <h5>1. TARAFLAR</h5>
        <p>
          İşbu Sözleşme aşağıdaki taraflar arasında aşağıda belirtilen hüküm ve
          şartlar çerçevesinde imzalanmıştır.
          <br />
          <br />
          <strong>ALICI :</strong> Bir mal veya hizmeti ticari veya mesleki
          olmayan amaçlarla edinen, kullanan veya yararlanan gerçek kişiyi,
          <br />
          <strong>SATICI :</strong> UBİCRO TEKNOLOJİ YAZILIM SANAYİ TİCARET
          ANONİM ŞİRKETİ,
          <br />
          <br /> İş bu sözleşmeyi kabul etmekle ALICI, sözleşme konusu siparişi
          onayladığı takdirde sipariş konusu bedeli ve varsa kargo ücreti, vergi
          gibi belirtilen ek ücretleri ödeme yükümlülüğü altına gireceğini ve bu
          konuda bilgilendirildiğini peşinen kabul eder.
          <h5>2. TANIMLAR</h5>
          İşbu sözleşmenin uygulanmasında ve yorumlanmasında aşağıda yazılı
          terimler karşılarındaki yazılı açıklamaları ifade edeceklerdir.
          <br />
          <br />
          BAKAN : Gümrük ve Ticaret Bakanı’nı,
          <br />
          <br />
          BAKANLIK : Gümrük ve Ticaret Bakanlığı’nı,
          <br />
          <br />
          KANUN : 6502 sayılı Tüketicinin Korunması Hakkında Kanun’u,
          <br />
          <br />
          YÖNETMELİK : Mesafeli Sözleşmeler Yönetmeliği’ni
          <br />
          <br />
          HİZMET : Bir ücret veya menfaat karşılığında yapılan ya da yapılması
          taahhüt edilen mal sağlama dışındaki her türlü tüketici işleminin
          konusunu ,
          <br />
          <br />
          SATICI : Ticari veya mesleki faaliyetleri kapsamında tüketiciye mal
          sunan veya mal sunan adına veya hesabına hareket eden şirketi,
          <br />
          <br />
          ALICI : Bir mal veya hizmeti ticari veya mesleki olmayan amaçlarla
          edinen, kullanan veya yararlanan gerçek ya da tüzel kişiyi,
          <br />
          <br />
          SİTE : SATICI’ya ait internet sitesini,
          <br />
          <br />
          SİPARİŞ VEREN: Bir mal veya hizmeti SATICI’ya ait internet sitesi
          üzerinden talep eden gerçek ya da tüzel kişiyi,
          <br />
          <br />
          TARAFLAR : SATICI ve ALICI’yı,
          <br />
          <br />
          SÖZLEŞME : SATICI ve ALICI arasında akdedilen işbu sözleşmeyi,
          <br />
          <br />
          MAL : Alışverişe konu olan taşınır eşyayı ve elektronik ortamda
          kullanılmak üzere hazırlanan yazılım, ses, görüntü ve benzeri gayri
          maddi malları ifade eder.
          <h5>3. KONU</h5>
          İşbu Sözleşme, ALICI’nın, SATICI’ya ait internet sitesi üzerinden
          elektronik ortamda siparişini verdiği aşağıda nitelikleri ve satış
          fiyatı belirtilen ürünün satışı ve teslimi ile ilgili olarak 6502
          sayılı Tüketicinin Korunması Hakkında Kanun ve Mesafeli Sözleşmelere
          Dair Yönetmelik hükümleri gereğince tarafların hak ve yükümlülüklerini
          düzenler.
          <br />
          <br />
          Listelenen ve sitede ilan edilen fiyatlar satış fiyatıdır. İlan edilen
          fiyatlar ve vaatler güncelleme yapılana ve değiştirilene kadar
          geçerlidir. Süreli olarak ilan edilen fiyatlar ise belirtilen süre
          sonuna kadar geçerlidir.
          <h5>4. SATICI BİLGİLERİ</h5>
          <ul>
            <li>
              Satıcı İsim/Unvanı : UBİCRO TEKNOLOJİ YAZILIM SANAYİ TİCARET
              ANONİM ŞİRKETİ
            </li>
            <li>Satıcının Açık Adresi : Maslak 1453 T4-B 31</li>
            <li>Satıcının Telefonu : 0 530 333 20 50</li>
            <li>Satıcı Mersis No : 8831035500</li>
            <li>Satıcı E-Posta : info@ubicro.com</li>
            <li>
              Alıcının İade Halinde Malı Satıcıya Göndereceği Kargo Şirketi :
              İade kodu alındığında tarafınıza bilgilendirilen kargo firmasıyla
              iadenizi gönderebilirsiniz.
            </li>
          </ul>
          <h5>5. SÖZLEŞME KONUSU ÜRÜN/ÜRÜNLER BİLGİLERİ</h5>
          5.1. Malın /Ürün/Ürünlerin/ Hizmetin temel özelliklerini (türü,
          miktarı, marka/modeli, rengi, adedi) SATICI’ya ait internet sitesinde
          yayınlanmaktadır. Satıcı tarafından kampanya düzenlenmiş ise ilgili
          ürünün temel özelliklerini kampanya süresince inceleyebilirsiniz.
          Kampanya tarihine kadar geçerlidir.
          <br />
          <br />
          5.2. Listelenen ve sitede ilan edilen fiyatlar satış fiyatıdır. İlan
          edilen fiyatlar ve vaatler güncelleme yapılana ve değiştirilene kadar
          geçerlidir. Süreli olarak ilan edilen fiyatlar ise belirtilen süre
          sonuna kadar geçerlidir.
          <br />
          <br />
          5.3. Sözleşme konusu mal ya da hizmetin tüm vergiler dâhil satış
          fiyatı aşağıda gösterilmiştir.
          <h5>6. GENEL HÜKÜMLER</h5>
          6.1. ALICI, SATICI’ya ait internet sitesinde sözleşme konusu ürünün
          temel nitelikleri, satış fiyatı ve ödeme şekli ile teslimata ilişkin
          ön bilgileri okuyup, bilgi sahibi olduğunu, elektronik ortamda gerekli
          teyidi verdiğini kabul, beyan ve taahhüt eder. ALICI’nın; Ön
          Bilgilendirmeyi elektronik ortamda teyit etmesi, mesafeli satış
          sözleşmesinin kurulmasından evvel, SATICI tarafından ALICI' ya
          verilmesi gereken adresi, siparişi verilen ürünlere ait temel
          özellikleri, ürünlerin vergiler dâhil fiyatını, ödeme ve teslimat
          bilgilerini de doğru ve eksiksiz olarak edindiğini kabul, beyan ve
          taahhüt eder.
          <br />
          <br />
          6.2. Sözleşme konusu her bir ürün, 30 günlük yasal süreyi aşmamak
          kaydı ile ALICI' nın yerleşim yeri uzaklığına bağlı olarak internet
          sitesindeki ön bilgiler kısmında belirtilen süre zarfında ALICI veya
          ALICI’nın gösterdiği adresteki kişi ve/veya kuruluşa teslim edilir. Bu
          süre içinde ürünün ALICI’ya teslim edilememesi durumunda, ALICI’nın
          sözleşmeyi feshetme hakkı saklıdır.
          <br />
          <br />
          6.3. SATICI, Sözleşme konusu ürünü eksiksiz, siparişte belirtilen
          niteliklere uygun ve varsa garanti belgeleri, kullanım kılavuzları
          işin gereği olan bilgi ve belgeler ile teslim etmeyi, her türlü
          ayıptan arî olarak yasal mevzuat gereklerine göre sağlam, standartlara
          uygun bir şekilde işi doğruluk ve dürüstlük esasları dâhilinde ifa
          etmeyi, hizmet kalitesini koruyup yükseltmeyi, işin ifası sırasında
          gerekli dikkat ve özeni göstermeyi, ihtiyat ve öngörü ile hareket
          etmeyi kabul, beyan ve taahhüt eder.
          <br />
          <br />
          6.4. SATICI, sözleşmeden doğan ifa yükümlülüğünün süresi dolmadan
          ALICI’yı bilgilendirmek ve açıkça onayını almak suretiyle eşit kalite
          ve fiyatta farklı bir ürün tedarik edebilir.
          <br />
          <br />
          6.5. SATICI, sipariş konusu ürün veya hizmetin yerine getirilmesinin
          imkânsızlaşması halinde sözleşme konusu yükümlülüklerini yerine
          getiremezse, bu durumu, öğrendiği tarihten itibaren 3 gün içinde
          yazılı olarak tüketiciye bildireceğini, 14 günlük süre içinde toplam
          bedeli ALICI’ya iade edeceğini kabul, beyan ve taahhüt eder.
          <br />
          <br />
          6.6. ALICI, Sözleşme konusu ürünün teslimatı için işbu Sözleşme’yi
          elektronik ortamda teyit edeceğini, herhangi bir nedenle sözleşme
          konusu ürün bedelinin ödenmemesi ve/veya banka kayıtlarında iptal
          edilmesi halinde, SATICI’nın sözleşme konusu ürünü teslim
          yükümlülüğünün sona ereceğini kabul, beyan ve taahhüt eder.
          <br />
          <br />
          6.7. ALICI, Sözleşme konusu ürünün ALICI veya ALICI’nın gösterdiği
          adresteki kişi ve/veya kuruluşa tesliminden sonra ALICI'ya ait kredi
          kartının yetkisiz kişilerce haksız kullanılması sonucunda sözleşme
          konusu ürün bedelinin ilgili banka veya finans kuruluşu tarafından
          SATICI'ya ödenmemesi halinde, ALICI Sözleşme konusu ürünü 3 gün
          içerisinde nakliye gideri SATICI’ya ait olacak şekilde SATICI’ya iade
          edeceğini kabul, beyan ve taahhüt eder.
          <br />
          <br />
          6.8. SATICI, tarafların iradesi dışında gelişen, önceden öngörülemeyen
          ve tarafların borçlarını yerine getirmesini engelleyici ve/veya
          geciktirici hallerin oluşması gibi mücbir sebepler halleri nedeni ile
          sözleşme konusu ürünü süresi içinde teslim edemez ise, durumu ALICI'ya
          bildireceğini kabul, beyan ve taahhüt eder. ALICI da siparişin iptal
          edilmesini, sözleşme konusu ürünün varsa emsali ile değiştirilmesini
          ve/veya teslimat süresinin engelleyici durumun ortadan kalkmasına
          kadar ertelenmesini SATICI’dan talep etme hakkını haizdir. ALICI
          tarafından siparişin iptal edilmesi halinde ALICI’nın nakit ile
          yaptığı ödemelerde, ürün tutarı 14 gün içinde kendisine nakden ve
          defaten ödenir. ALICI’nın kredi kartı ile yaptığı ödemelerde ise, ürün
          tutarı, siparişin ALICI tarafından iptal edilmesinden sonra 14 gün
          içerisinde ilgili bankaya iade edilir. ALICI, SATICI tarafından kredi
          kartına iade edilen tutarın banka tarafından ALICI hesabına
          yansıtılmasına ilişkin ortalama sürecin 2 ile 3 haftayı
          bulabileceğini, bu tutarın bankaya iadesinden sonra ALICI’nın
          hesaplarına yansıması halinin tamamen banka işlem süreci ile ilgili
          olduğundan, ALICI, olası gecikmeler için SATICI’yı sorumlu
          tutamayacağını kabul, beyan ve taahhüt eder.
          <br />
          <br />
          6.9. SATICININ, ALICI tarafından siteye kayıt formunda belirtilen veya
          daha sonra kendisi tarafından güncellenen adresi, e-posta adresi,
          sabit ve mobil telefon hatları ve diğer iletişim bilgileri üzerinden
          mektup, e-posta, SMS, telefon görüşmesi ve diğer yollarla iletişim,
          pazarlama, bildirim ve diğer amaçlarla ALICI’ya ulaşma hakkı
          bulunmaktadır. ALICI, işbu sözleşmeyi kabul etmekle SATICI’nın
          kendisine yönelik yukarıda belirtilen iletişim faaliyetlerinde
          bulunabileceğini kabul ve beyan etmektedir.
          <br />
          <br />
          6.10. ALICI, sözleşme konusu mal/hizmeti teslim almadan önce muayene
          edecek; ezik, kırık, ambalajı yırtılmış vb. hasarlı ve ayıplı
          mal/hizmeti kargo şirketinden teslim almayacaktır. Teslim alınan
          mal/hizmetin hasarsız ve sağlam olduğu kabul edilecektir. Teslimden
          sonra mal/hizmetin özenle korunması borcu, ALICI’ya aittir. Cayma
          hakkı kullanılacaksa mal/hizmet kullanılmamalıdır. Fatura iade
          edilmelidir.
          <br />
          <br />
          6.11. ALICI ile sipariş esnasında kullanılan kredi kartı hamilinin
          aynı kişi olmaması veya ürünün ALICI’ya tesliminden evvel, siparişte
          kullanılan kredi kartına ilişkin güvenlik açığı tespit edilmesi
          halinde, SATICI, kredi kartı hamiline ilişkin kimlik ve iletişim
          bilgilerini, siparişte kullanılan kredi kartının bir önceki aya ait
          ekstresini yahut kart hamilinin bankasından kredi kartının kendisine
          ait olduğuna ilişkin yazıyı ibraz etmesini ALICI’dan talep edebilir.
          ALICI’nın talebe konu bilgi/belgeleri temin etmesine kadar geçecek
          sürede sipariş dondurulacak olup, mezkur taleplerin 24 saat içerisinde
          karşılanmaması halinde ise SATICI, siparişi iptal etme hakkını
          haizdir.
          <br />
          <br />
          6.12. ALICI, SATICI’ya ait internet sitesine üye olurken verdiği
          kişisel ve diğer sair bilgilerin gerçeğe uygun olduğunu, SATICI’nın bu
          bilgilerin gerçeğe aykırılığı nedeniyle uğrayacağı tüm zararları,
          SATICI’nın ilk bildirimi üzerine derhal, nakden ve defaten tazmin
          edeceğini beyan ve taahhüt eder.
          <br />
          <br />
          6.13. ALICI, SATICI’ya ait internet sitesini kullanırken yasal mevzuat
          hükümlerine riayet etmeyi ve bunları ihlal etmemeyi baştan kabul ve
          taahhüt eder. Aksi takdirde, doğacak tüm hukuki ve cezai yükümlülükler
          tamamen ve münhasıran ALICI’yı bağlayacaktır.
          <br />
          <br />
          6.14. ALICI, SATICI’ya ait internet sitesini hiçbir şekilde kamu
          düzenini bozucu, genel ahlaka aykırı, başkalarını rahatsız ve taciz
          edici şekilde, yasalara aykırı bir amaç için, başkalarının maddi ve
          manevi haklarına tecavüz edecek şekilde kullanamaz. Ayrıca, üye
          başkalarının hizmetleri kullanmasını önleyici veya zorlaştırıcı
          faaliyet (spam, virus, truva atı, vb.) işlemlerde bulunamaz.
          <br />
          <br />
          6.15. SATICI’ya ait internet sitesinin üzerinden, SATICI’nın kendi
          kontrolünde olmayan ve/veya başkaca üçüncü kişilerin sahip olduğu
          ve/veya işlettiği başka web sitelerine ve/veya başka içeriklere link
          verilebilir. Bu linkler ALICI’ya yönlenme kolaylığı sağlamak amacıyla
          konmuş olup herhangi bir web sitesini veya o siteyi işleten kişiyi
          desteklememekte ve Link verilen web sitesinin içerdiği bilgilere
          yönelik herhangi bir garanti niteliği taşımamaktadır.
          <br />
          <br />
          6.16. İşbu sözleşme içerisinde sayılan maddelerden bir ya da birkaçını
          ihlal eden üye işbu ihlal nedeniyle cezai ve hukuki olarak şahsen
          sorumlu olup, SATICI’yı bu ihlallerin hukuki ve cezai sonuçlarından
          ari tutacaktır. Ayrıca; işbu ihlal nedeniyle, olayın hukuk alanına
          intikal ettirilmesi halinde, SATICI’nın üyeye karşı üyelik
          sözleşmesine uyulmamasından dolayı tazminat talebinde bulunma hakkı
          saklıdır.
          <h5>7. CAYMA HAKKI</h5>
          7.1. ALICI; mesafeli sözleşmenin mal satışına ilişkin olması
          durumunda, ürünün kendisine veya gösterdiği adresteki kişi/kuruluşa
          teslim tarihinden itibaren 14 (on dört) gün içerisinde, SATICI’ya
          bildirmek şartıyla hiçbir hukuki ve cezai sorumluluk üstlenmeksizin ve
          hiçbir gerekçe göstermeksizin malı reddederek sözleşmeden cayma
          hakkını kullanabilir. Hizmet sunumuna ilişkin mesafeli sözleşmelerde
          ise, bu süre sözleşmenin imzalandığı tarihten itibaren başlar. Cayma
          hakkı süresi sona ermeden önce, tüketicinin onayı ile hizmetin ifasına
          başlanan hizmet sözleşmelerinde cayma hakkı kullanılamaz. Cayma
          hakkının kullanımından kaynaklanan masraflar SATICI’ ya aittir. ALICI,
          iş bu sözleşmeyi kabul etmekle, cayma hakkı konusunda
          bilgilendirildiğini peşinen kabul eder.
          <br />
          <br />
          7.2. Cayma hakkının kullanılması için 14 (ondört) günlük süre içinde
          SATICI' ya iadeli taahhütlü posta, faks veya eposta ile yazılı
          bildirimde bulunulması ve ürünün işbu sözleşmede düzenlenen "Cayma
          Hakkı Kullanılamayacak Ürünler" hükümleri çerçevesinde kullanılmamış
          olması şarttır. Bu hakkın kullanılması halinde,
          <br />
          <ol type="a">
            <li>
              3. kişiye veya ALICI’ ya teslim edilen ürünün faturası, (İade
              edilmek istenen ürünün faturası kurumsal ise, iade ederken kurumun
              düzenlemiş olduğu iade faturası ile birlikte gönderilmesi
              gerekmektedir. Faturası kurumlar adına düzenlenen sipariş iadeleri
              İADE FATURASI kesilmediği takdirde tamamlanamayacaktır.)
            </li>
            <br />
            <li>İade formu,</li>
            <br />
            <li>
              İade edilecek ürünlerin kutusu, ambalajı, varsa standart
              aksesuarları ile birlikte eksiksiz ve hasarsız olarak teslim
              edilmesi gerekmektedir.
            </li>
            <br />
            <li>
              Cayma hakkının kullanılması nedeniyle SATICI tarafından düzenlenen
              kampanya limit tutarının altına düşülmesi halinde kampanya
              kapsamında faydalanılan indirim miktarı iptal edilir.
            </li>
            <br />
            <li>
              SATICI, cayma bildiriminin kendisine ulaşmasından itibaren en geç
              10 günlük süre içerisinde toplam bedeli ve ALICI’yı borç altına
              sokan belgeleri ALICI’ ya iade etmek ve 20 günlük süre içerisinde
              malı iade almakla yükümlüdür.
            </li>
            <br />
            <li>
              ALICI’ nın kusurundan kaynaklanan bir nedenle malın değerinde bir
              azalma olursa veya iade imkânsızlaşırsa ALICI kusuru oranında
              SATICI’ nın zararlarını tazmin etmekle yükümlüdür. Ancak cayma
              hakkı süresi içinde malın veya ürünün usulüne uygun kullanılması
              sebebiyle meydana gelen değişiklik ve bozulmalardan ALICI sorumlu
              değildir.
            </li>
          </ol>
          <h5>8. YETKİLİ MAHKEME</h5>
          İşbu sözleşmeden doğan uyuşmazlıklarda şikayet ve itirazlar, aşağıdaki
          kanunda belirtilen parasal sınırlar dâhilinde tüketicinin yerleşim
          yerinin bulunduğu veya tüketici işleminin yapıldığı yerdeki tüketici
          sorunları hakem heyetine veya tüketici mahkemesine yapılacaktır.
          Parasal sınıra ilişkin bilgiler aşağıdadır:
          <br />
          <br />
          28/05/2014 tarihinden itibaren geçerli olmak üzere:
          <ol type="a">
            <li>
              6502 sayılı Tüketicinin Korunması Hakkında Kanun’un 68. Maddesi
              gereği değeri 2.000,00 (ikibin) TL’nin altında olan
              uyuşmazlıklarda ilçe tüketici hakem heyetlerine,
            </li>
            <br />
            <li>
              Değeri 3.000,00(üçbin)TL’ nin altında bulunan uyuşmazlıklarda il
              tüketici hakem heyetlerine,
            </li>
            <br />
            <li>
              Büyükşehir statüsünde bulunan illerde ise değeri 2.000,00 (ikibin)
              TL ile 3.000,00(üçbin)TL’ arasındaki uyuşmazlıklarda il tüketici
              hakem heyetlerine başvuru yapılmaktadır. İşbu Sözleşme ticari
              amaçlarla yapılmaktadır.
            </li>
          </ol>
        </p>
      </DialogContentText>
    </Fragment>
  );
};

export default MesafeliSatisSozlesmesi;
