// BannerMedia.jsx
import React, { useState } from "react";
import { Box, Skeleton } from "@mui/material";

const BannerMedia = ({ banner, commonStyles }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleOnLoad = () => {
    setIsLoaded(true);
  };

  if (banner.media_type === "image" || banner.media_type === "gif") {
    return (
      <Box sx={{ position: "relative", ...commonStyles }}>
        {!isLoaded && (
          <Skeleton variant="rectangular" width="100%" height="300px" />
        )}

        <img
          src={banner.image_file}
          alt={banner.title || "Banner"}
          style={{
            ...commonStyles,
            display: isLoaded ? "block" : "none",
          }}
          onLoad={handleOnLoad}
        />
      </Box>
    );
  }

  if (banner.media_type === "video") {
    return (
      <Box sx={{ position: "relative", ...commonStyles }}>
        {!isLoaded && (
          <Skeleton variant="rectangular" width="100%" height="300px" />
        )}
        <video
          src={banner.video_file}
          style={{
            ...commonStyles,
            display: isLoaded ? "block" : "none",
          }}
          controls
          onLoadedData={handleOnLoad}
        >
          Your browser does not support the video tag.
        </video>
      </Box>
    );
  }

  return null;
};

export default BannerMedia;
