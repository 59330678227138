import React from "react";
import { Grid, Typography, Divider } from "@mui/material";

// Logo imports
import ProntoLogo from "../assets/images/pronto-logo.svg";
import KworksLogo from "../assets/images/kworks-logo.png";
import KosgebLogo from "../assets/images/kosgeb-logo.png";
import BinovistLogo from "../assets/images/binovist-logo.png";
import NvidiaLogo from "../assets/images/nvidia-logo-horz.png";

function Partners() {
  let width = window.innerWidth;
  if (width >= 1500) {
    return (
      <>
        <Divider style={{ margin: 40 }} />
        <Grid
          container
          direction="column"
          style={{
            paddingBottom: 60,
            paddingTop: 30,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h4">Partnerlerimiz</Typography>
        </Grid>
        <Grid
          container
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <img src={ProntoLogo} alt="ProntoTour logo" style={styles.logos} />
          <img src={KworksLogo} alt="Kworks logo" style={styles.logos} />
          <img src={BinovistLogo} alt="Binovist logo" style={styles.logos} />
          <img src={NvidiaLogo} alt="Nvidia logo" style={styles.logos} />
          <img src={KosgebLogo} alt="KOSGEB logo" style={styles.logos} />
        </Grid>
        <Divider style={{ margin: 40 }} />
      </>
    );
  }
  if ((width > 1300) & (width < 1500)) {
    return (
      <>
        <Divider style={{ margin: 40 }} />
        <Grid
          container
          direction="column"
          style={{
            paddingBottom: 60,
            paddingTop: 30,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h4">Partnerlerimiz</Typography>
        </Grid>
        <div
          style={{
            overflow: "auto",
            whiteSpace: "nowrap",
            width: "100%",
          }}
        >
          <img src={ProntoLogo} alt="ProntoTour logo" style={styles.logos} />
          <img src={KworksLogo} alt="Kworks logo" style={styles.logos} />
          <img src={BinovistLogo} alt="Binovist logo" style={styles.logos} />
          <img src={NvidiaLogo} alt="Nvidia logo" style={styles.logos} />
          <img src={KosgebLogo} alt="KOSGEB logo" style={styles.logos} />
        </div>
        <Divider style={{ margin: 40 }} />
      </>
    );
  } else {
    return (
      <>
        <Divider style={{ margin: 40 }} />
        <Grid
          container
          direction="column"
          style={{
            paddingBottom: 60,
            paddingTop: 30,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h4">Partnerlerimiz</Typography>
        </Grid>
        <Grid
          container
          style={{
            paddingBottom: 60,
            paddingTop: 30,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={ProntoLogo}
            alt="ProntoTour logo"
            style={styles.small_logos}
          />
          <img src={KworksLogo} alt="Kworks logo" style={styles.small_logos} />
          <img src={NvidiaLogo} alt="Nvidia logo" style={styles.small_logos} />
          <img
            src={BinovistLogo}
            alt="Binovist logo"
            style={styles.small_logos}
          />
          <img src={KosgebLogo} alt="KOSGEB logo" style={styles.small_logos} />
        </Grid>
        <Divider style={{ margin: 40 }} />
      </>
    );
  }
}

const styles = {
  logos: {
    maxWidth: 200,
    maxHeight: 80,
    margin: 20,
  },
  small_logos: {
    maxWidth: 100,
    maxHeight: 40,
    margin: 10,
  },
};

export default Partners;
