import React from "react";
import { Grid, Typography, Button } from "@mui/material";

const Card = ({ product }) => {
  console.log(product.presale_price);

  // Extract text before the first dot in product.description
  const shortDescription = product.description.split(".")[0];

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#F8F8F8",
      }}
    >
      <Typography variant="h4" style={{ textAlign: "center", marginTop: 30 }}>
        {product.name}
      </Typography>

      {/* Display the shortened description */}
      <Typography style={{ marginTop: 20, textAlign: "center", maxWidth: 400 }}>
        {shortDescription}.
      </Typography>

      {/* Display the price */}
      {product.presale_price === null ? (
        <Typography style={{ color: "#6E6E6E", marginTop: 10 }}>
          {product.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ₺
        </Typography>
      ) : (
        <Typography style={{ color: "#6E6E6E", marginTop: 10 }}>
          {product.presale_price
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
          ₺
        </Typography>
      )}

      <Button
        variant="contained"
        href={`/product/${product.slug}`}
        style={{
          height: 40,
          width: 100,
          backgroundColor: "#2BA24C",
          marginTop: 20,
          marginBottom: 20,
          textTransform: "none",
          borderRadius: 50,
        }}
      >
        İncele
      </Button>
      <img
        alt="Product Visual"
        src={product.image}
        style={{ width: "90%", maxWidth: 500, maxHeight: 400 }}
      />
    </Grid>
  );
};

export default Card;
