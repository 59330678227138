import React from "react";
import DOMPurify from "dompurify";
import { Typography, Grid, Divider, useMediaQuery } from "@mui/material";

export default function ProductDetailGIFs({ product }) {
  const isDesktop = useMediaQuery("(min-width:800px)");

  const gifEntries = [
    { src: product.video_1, text: product.video_1_text, alt: "Video 1" },
    { src: product.video_2, text: product.video_2_text, alt: "Video 2" },
    { src: product.video_3, text: product.video_3_text, alt: "Video 3" },
    { src: product.video_4, text: product.video_4_text, alt: "Video 4" },
    { src: product.video_5, text: product.video_5_text, alt: "Video 5" },
  ];

  // Filter out null or empty entries
  const validGifs = gifEntries.filter((entry) => entry.src);

  const renderGifWithOverlay = (src, alt) => (
    <div style={{ position: "relative", width: "100%", maxWidth: 600 }}>
      <img
        src={src}
        alt={alt}
        width="100%"
        style={{
          borderRadius: 20,
          display: "block",
          objectFit: "cover",
        }}
      />
    </div>
  );

  return (
    <Grid
      container
      justifyContent="space-evenly"
      alignItems="center"
      direction={isDesktop ? "row" : "column"}
      style={{
        borderRadius: 10,
        padding: isDesktop ? 20 : 10,
        paddingTop: isDesktop ? 60 : 30,
        paddingBottom: isDesktop ? 100 : 50,
        maxWidth: 1300,
        margin: "0 auto",
      }}
    >
      {validGifs.length === 0 ? (
        <></>
      ) : (
        validGifs.map((entry, index) => {
          // Sanitize each text inside the map
          const safeHTML = DOMPurify.sanitize(entry.text || "");
          return (
            <React.Fragment key={index}>
              <Grid item xs={isDesktop ? 6 : 12}>
                {renderGifWithOverlay(entry.src, entry.alt)}
              </Grid>

              <Grid item xs={isDesktop ? 6 : 12} style={{ padding: 30 }}>
                <Typography
                  variant="h6"
                  dangerouslySetInnerHTML={{ __html: safeHTML }}
                />
              </Grid>

              {index < validGifs.length - 1 && (
                <Divider
                  style={{ width: "100%", marginTop: 100, marginBottom: 100 }}
                />
              )}
            </React.Fragment>
          );
        })
      )}
    </Grid>
  );
}
