import React, { Fragment } from "react";
import {
  Typography,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemIcon,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

export default function ProductDetailAdvancedDetail({ product }) {
  const width = window.innerWidth;
  if (width > 800) {
    return (
      <Fragment>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 100 }}
        >
          <Typography
            style={{
              fontSize: "4rem",
              fontWeight: "bold",
              background:
                "linear-gradient(90deg, #69BFF9 0%, #2BA24C 35%, #B7CDE8 74%, #15922E 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              display: "inline-block",
            }}
            variant="h2"
          >
            Detaylı Bilgi
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{
            marginTop: 60,
            marginBottom: 150,
            maxWidth: 1200,
          }}
        >
          <Divider
            light
            flexItem
            variant="middle"
            style={{
              width: "100%",
            }}
          />

          <Grid item sm={12} lg={6} style={{ maxWidth: 600, padding: 40 }}>
            <Typography gutterBottom variant="h5">
              Beraberinde gelenler
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <CircleIcon
                    style={{
                      color: "#717478",
                      fontSize: 14,
                    }}
                  />
                </ListItemIcon>
                <Typography
                  style={{
                    color: "#717478",
                    letterSpacing: 1,
                    fontWeight: 100,
                    fontSize: 22,
                  }}
                >
                  {product.name}
                </Typography>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <CircleIcon
                    style={{
                      color: "#717478",
                      fontSize: 14,
                    }}
                  />
                </ListItemIcon>
                <Typography
                  style={{
                    color: "#717478",
                    letterSpacing: 1,
                    fontWeight: 100,
                    fontSize: 22,
                  }}
                >
                  UbiEnergy (besin ve pH dengeleyici)
                </Typography>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <CircleIcon
                    style={{
                      color: "#717478",
                      fontSize: 14,
                    }}
                  />
                </ListItemIcon>
                <Typography
                  style={{
                    color: "#717478",
                    letterSpacing: 1,
                    fontWeight: 100,
                    fontSize: 22,
                  }}
                >
                  {product.birlikte_gelenler_ubitray}
                </Typography>
              </ListItem>
            </List>
          </Grid>

          <Divider
            light
            orientation="vertical"
            variant="fullWidth"
            flexItem
            style={{ marginLeft: 20, marginRight: 20 }}
          />

          <Grid item sm={12} lg={4} style={{ maxWidth: 600, padding: 40 }}>
            <Typography variant="h5">2 Yıl garanti süresi</Typography>
          </Grid>

          <Divider
            light
            flexItem
            variant="middle"
            style={{
              width: "100%",
            }}
          />

          <Grid item sm={12} lg={4} style={{ maxWidth: 600, padding: 40 }}>
            <Typography gutterBottom variant="h5">
              Ağırlık
            </Typography>
            <Typography
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 22,
              }}
            >
              {product.agırlık}
            </Typography>
          </Grid>

          <Divider
            light
            orientation="vertical"
            variant="fullWidth"
            flexItem
            style={{ marginLeft: 20, marginRight: 20 }}
          />

          <Grid item sm={12} lg={6} style={{ maxWidth: 600, padding: 40 }}>
            <Typography variant="h5">Boyutlar</Typography>
            <Typography
              gutterBottom
              variant="subtitle1"
              style={{
                color: "#8D8D8D",
                fontWeight: 100,
              }}
            >
              (yükseklik, genişlik, derinlik)
            </Typography>
            <Typography
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 22,
              }}
            >
              {product.boyutlar}
            </Typography>
          </Grid>

          <Divider
            light
            flexItem
            variant="middle"
            style={{
              width: "100%",
            }}
          />

          <Grid item sm={12} lg={6} style={{ maxWidth: 600, padding: 40 }}>
            <Typography gutterBottom variant="h5">
              Bitkiler için ayrılmış yükseklik
            </Typography>
            <Typography
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 22,
              }}
            >
              35 cm (her katta)
            </Typography>
          </Grid>

          <Divider
            light
            orientation="vertical"
            variant="fullWidth"
            flexItem
            style={{ marginLeft: 20, marginRight: 20 }}
          />

          <Grid item sm={12} lg={4} style={{ maxWidth: 600, padding: 40 }}>
            <Typography gutterBottom variant="h5">
              Su deposu genişliği
            </Typography>
            <Typography
              gutterBottom
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 22,
              }}
            >
              {product.su_deposu_genislik}
            </Typography>
            <Typography
              variant="subtitle1"
              style={{
                color: "#8D8D8D",
                fontWeight: 100,
              }}
            >
              {product.su_deposu_yenileme}
            </Typography>
          </Grid>

          <Divider
            light
            flexItem
            variant="middle"
            style={{
              width: "100%",
            }}
          />

          <Grid item sm={12} lg={4} style={{ maxWidth: 600, padding: 40 }}>
            <Typography gutterBottom variant="h5">
              Elektrik tüketimi
            </Typography>
            <Typography
              gutterBottom
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 22,
              }}
            >
              {product.elektrik_tuketimi}
            </Typography>
            <Typography
              variant="subtitle1"
              style={{
                color: "#8D8D8D",
                fontWeight: 100,
              }}
            >
              AC 220 - 240 V
            </Typography>
          </Grid>

          <Divider
            light
            orientation="vertical"
            variant="fullWidth"
            flexItem
            style={{ marginLeft: 20, marginRight: 20 }}
          />

          <Grid item sm={12} lg={6} style={{ maxWidth: 600, padding: 40 }}>
            <Typography gutterBottom variant="h5">
              Yetiştirme alanı
            </Typography>
            <Typography
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 22,
              }}
            >
              {product.yetistirme_alani}
            </Typography>
          </Grid>

          <Divider
            light
            flexItem
            variant="middle"
            style={{
              width: "100%",
            }}
          />
        </Grid>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 100 }}
        >
          <Typography
            style={{
              fontSize: "2rem",
              fontWeight: "bold",
              background:
                "linear-gradient(90deg, #69BFF9 0%, #2BA24C 35%, #B7CDE8 74%, #15922E 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              display: "inline-block",
            }}
            variant="h2"
          >
            Detaylı Bilgi
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{
            marginTop: 60,
            marginBottom: 150,
            maxWidth: 1200,
          }}
        >
          <Divider
            light
            flexItem
            variant="middle"
            style={{
              width: "100%",
            }}
          />

          <Grid item sm={12} lg={6} style={{ maxWidth: 600, padding: 40 }}>
            <Typography gutterBottom variant="h5">
              Beraberinde gelenler
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <CircleIcon
                    style={{
                      color: "#717478",
                      fontSize: 18,
                    }}
                  />
                </ListItemIcon>
                <Typography
                  style={{
                    color: "#717478",
                    letterSpacing: 1,
                    fontWeight: 100,
                    fontSize: 18,
                  }}
                >
                  {product.name}
                </Typography>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <CircleIcon
                    style={{
                      color: "#717478",
                      fontSize: 18,
                    }}
                  />
                </ListItemIcon>
                <Typography
                  style={{
                    color: "#717478",
                    letterSpacing: 1,
                    fontWeight: 100,
                    fontSize: 18,
                  }}
                >
                  UbiEnergy (besin ve pH dengeleyici)
                </Typography>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <CircleIcon
                    style={{
                      color: "#717478",
                      fontSize: 18,
                    }}
                  />
                </ListItemIcon>
                <Typography
                  style={{
                    color: "#717478",
                    letterSpacing: 1,
                    fontWeight: 100,
                    fontSize: 18,
                  }}
                >
                  {product.birlikte_gelenler_ubitray}
                </Typography>
              </ListItem>
            </List>
          </Grid>

          <Grid item sm={12} lg={4} style={{ maxWidth: 600, padding: 40 }}>
            <Typography variant="h5">2 Yıl garanti süresi</Typography>
          </Grid>

          <Divider
            light
            flexItem
            variant="middle"
            style={{
              width: "100%",
            }}
          />

          <Grid item sm={12} style={{ maxWidth: 600, padding: 40 }}>
            <Typography gutterBottom variant="h5">
              Ağırlık
            </Typography>
            <Typography
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 18,
              }}
            >
              {product.agırlık}
            </Typography>
          </Grid>

          <Grid item sm={12} lg={6} style={{ maxWidth: 600, padding: 40 }}>
            <Typography variant="h5">Boyutlar</Typography>
            <Typography
              gutterBottom
              variant="subtitle1"
              style={{
                color: "#8D8D8D",
                fontWeight: 100,
              }}
            >
              (yükseklik, genişlik, derinlik)
            </Typography>
            <Typography
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 18,
              }}
            >
              {product.boyutlar}
            </Typography>
          </Grid>

          <Divider
            light
            flexItem
            variant="middle"
            style={{
              width: "100%",
            }}
          />

          <Grid item sm={12} lg={6} style={{ maxWidth: 600, padding: 40 }}>
            <Typography gutterBottom variant="h5">
              Bitkiler için ayrılmış yükseklik
            </Typography>
            <Typography
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 18,
              }}
            >
              35 cm (her katta)
            </Typography>
          </Grid>

          <Grid item sm={12} lg={4} style={{ maxWidth: 600, padding: 40 }}>
            <Typography gutterBottom variant="h5">
              Su deposu genişliği
            </Typography>
            <Typography
              gutterBottom
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 18,
              }}
            >
              {product.su_deposu_genislik}
            </Typography>
            <Typography
              variant="subtitle1"
              style={{
                color: "#8D8D8D",
                fontWeight: 100,
              }}
            >
              {product.su_deposu_yenileme}
            </Typography>
          </Grid>

          <Divider
            light
            flexItem
            variant="middle"
            style={{
              width: "100%",
            }}
          />

          <Grid item sm={12} lg={4} style={{ maxWidth: 600, padding: 40 }}>
            <Typography gutterBottom variant="h5">
              Elektrik tüketimi
            </Typography>
            <Typography
              gutterBottom
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 18,
              }}
            >
              {product.elektrik_tuketimi}
            </Typography>
            <Typography
              variant="subtitle1"
              style={{
                color: "#8D8D8D",
                fontWeight: 100,
              }}
            >
              AC 220 - 240 V
            </Typography>
          </Grid>

          <Grid item sm={12} lg={6} style={{ maxWidth: 600, padding: 40 }}>
            <Typography gutterBottom variant="h5">
              Yetiştirme alanı
            </Typography>
            <Typography
              style={{
                color: "#717478",
                letterSpacing: 1,
                fontWeight: 100,
                fontSize: 18,
              }}
            >
              {product.yetistirme_alani}
            </Typography>
          </Grid>

          <Divider
            light
            flexItem
            variant="middle"
            style={{
              width: "100%",
            }}
          />
        </Grid>
      </Fragment>
    );
  }
}
