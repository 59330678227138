import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import WifiIcon from "@mui/icons-material/Wifi";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import GrassIcon from "@mui/icons-material/Grass";

const relatedProductsCard = ({ product }) => {
  let width = window.innerWidth;
  if (width > 800) {
    return (
      <div>
        <Grid container>
          <Grid
            container
            direction="column"
            justifyContent="center"
            style={{
              display: "flex",
              alignItems: "center",
              width: 280,
              marginBottom: 30,
            }}
          >
            <Button href={`/product/${product.slug}`}>
              <img
                alt="Product Visual"
                src={product.image}
                style={{
                  maxWidth: 300,
                  maxHeight: 300,
                  marginBottom: 20,
                }}
              />
            </Button>
            <Button
              color="inherit"
              href={`/product/${product.slug}`}
              style={{ fontSize: 18, textTransform: "none" }}
            >
              {product.name}
            </Button>
            <Typography
              style={{
                color: "#6E6E6E",
                marginTop: 10,
                marginBottom: 50,
              }}
            >
              {product.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ₺
            </Typography>
            <Grid container justifyContent="center" spacing={8}>
              <Grid item>
                <Grid container justifyContent="center">
                  <Typography gutterBottom>{product.boyutlar}</Typography>
                  <Typography style={{ color: "grey", fontSize: 12 }}>
                    (yükseklik, genişlik, derinlik)
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container>
                  <Typography>{product.yetistirme_alani}</Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container alignItems="center" direction="column">
                  <GrassIcon
                    style={{
                      fontSize: 50,
                      marginBottom: 10,
                      color: "#157EFB",
                    }}
                  />
                  <Typography>
                    {product.marul_sayisi} Marul yetiştirebilir / Ayda
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container alignItems="center" direction="column">
                  <WifiIcon
                    style={{
                      fontSize: 50,
                      marginBottom: 10,
                      color: "#157EFB",
                    }}
                  />
                  <Typography>Wifi ve App bağlantısı</Typography>
                </Grid>
              </Grid>

              <Grid item>
                {product.id <= 2 ? (
                  <Grid container alignItems="center" direction="column">
                    <DoNotDisturbIcon
                      style={{
                        fontSize: 50,
                        marginBottom: 10,
                        color: "#157EFB",
                      }}
                    />
                    <Typography>İklimlendirme yok</Typography>
                  </Grid>
                ) : (
                  <Grid container alignItems="center" direction="column">
                    <AcUnitIcon
                      style={{
                        fontSize: 50,
                        marginBottom: 10,
                        color: "#157EFB",
                      }}
                    />
                    <Typography>İklimlendirme sistemi</Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return (
      <div>
        <Grid container>
          <Grid
            container
            direction="column"
            justifyContent="center"
            style={{
              display: "flex",
              alignItems: "center",
              width: 280,
              marginBottom: 60,
            }}
          >
            <Button href={`/product/${product.slug}`}>
              <img
                alt="Product Visual"
                src={product.image}
                style={{
                  maxWidth: 200,
                  maxHeight: 200,
                  marginBottom: 20,
                }}
              />
            </Button>
            <Button
              color="inherit"
              href={`/product/${product.slug}`}
              style={{ fontSize: 18, textTransform: "none" }}
            >
              {product.name}
            </Button>
            <Typography
              style={{
                color: "#6E6E6E",
                marginTop: 10,
                marginBottom: 50,
              }}
            >
              {product.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ₺
            </Typography>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item>
                <Grid container justifyContent="center">
                  <Typography gutterBottom>{product.boyutlar}</Typography>
                  <Typography style={{ color: "grey", fontSize: 12 }}>
                    (yükseklik, genişlik, derinlik)
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container>
                  <Typography>{product.yetistirme_alani}</Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container alignItems="center" direction="column">
                  <GrassIcon
                    style={{
                      fontSize: 50,
                      marginBottom: 10,
                      color: "#157EFB",
                    }}
                  />
                  <Typography>
                    {product.marul_sayisi} Marul yetiştirebilir / Ayda
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container alignItems="center" direction="column">
                  <WifiIcon
                    style={{
                      fontSize: 50,
                      marginBottom: 10,
                      color: "#157EFB",
                    }}
                  />
                  <Typography>Wifi ve App bağlantısı</Typography>
                </Grid>
              </Grid>
              <Grid item>
                {product.id <= 2 ? (
                  <Grid container alignItems="center" direction="column">
                    <DoNotDisturbIcon
                      style={{
                        fontSize: 50,
                        marginBottom: 10,
                        color: "#157EFB",
                      }}
                    />
                    <Typography>İklimlendirme yok</Typography>
                  </Grid>
                ) : (
                  <Grid container alignItems="center" direction="column">
                    <AcUnitIcon
                      style={{
                        fontSize: 50,
                        marginBottom: 10,
                        color: "#157EFB",
                      }}
                    />
                    <Typography>İklimlendirme sistemi</Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
};

export default relatedProductsCard;
