import { DialogContentText } from "@mui/material";
import React, { Fragment } from "react";

const Iptalveiade = () => {
  return (
    <Fragment>
      <DialogContentText>
        <p>Son güncellenme: 22/12/2024</p>
        <h2>İPTAL İADE KOŞULLARI</h2>
        <p>
          <h5>İPTAL KOŞULLARI</h5>
          Sipariş İptal Etme Hakkı Siparişinizi oluşturmanızın ardından 48 saat
          içerisinde iptal edebilirsiniz. Kullanıcı sayfanızdan iptal etmek
          istediğiniz siparişin sipariş kodunu info@ubicro.com mail adresine
          gönderip siparişinizi iptal ettirebilirsiniz.
          <br />
          <br />
          Ücret iadeniz sipariş durumunuz iptal edildiği andan itibaren
          gerçekleşmektedir. Kartınıza yansıması Bankadan bankaya değişebilmekle
          birlikte birkaç gün içinde gerçekleşmekte olup, detaylı takibini
          bankanızdan sağlayabilirsiniz.
        </p>
        <p>
          Kullanmakta olduğunuz web sitesi üzerinden elektronik ortamda sipariş
          verdiğiniz takdirde, size sunulan ön bilgilendirme formunu ve mesafeli
          satış sözleşmesini kabul etmiş sayılırsınız.
          <br />
          <br />
          Alıcılar, satın aldıkları ürünün satış ve teslimi ile ilgili olarak
          6502 sayılı Tüketicinin Korunması Hakkında Kanun ve Mesafeli
          Sözleşmeler Yönetmeliği (RG:27.11.2014/29188) hükümleri ile
          yürürlükteki diğer yasalara tabidir.
          <br />
          <br />
          Satın alınan her bir ürün, 30 günlük yasal süreyi aşmamak kaydı ile
          alıcının gösterdiği adresteki kişi ve/veya kuruluşa teslim edilir. Bu
          süre içinde ürün teslim edilmez ise, Alıcılar sözleşmeyi sona
          erdirebilir.
          <br />
          <br />
          Satın alınan ürün, eksiksiz ve siparişte belirtilen niteliklere uygun
          ve varsa garanti belgesi, kullanım klavuzu gibi belgelerle teslim
          edilmek zorundadır.
          <br />
          <br />
          Satın alınan ürünün satılmasının imkansızlaşması durumunda, satıcı bu
          durumu öğrendiğinden itibaren 3 gün içinde yazılı olarak alıcıya bu
          durumu bildirmek zorundadır. 14 gün içinde de toplam bedel Alıcı’ya
          iade edilmek zorundadır.
          <h5>KREDİ KARTININ YETKİSİZ KULLANIMI İLE YAPILAN ALIŞVERİŞLER</h5>
          Ürün teslim edildikten sonra, alıcının ödeme yaptığı kredi kartının
          yetkisiz kişiler tarafından haksız olarak kullanıldığı tespit edilirse
          ve satılan ürün bedeli ilgili banka veya finans kuruluşu tarafından
          Satıcı'ya ödenmez ise, Alıcı, sözleşme konusu ürünü 3 gün içerisinde
          nakliye gideri SATICI’ya ait olacak şekilde SATICI’ya iade etmek
          zorundadır.
          <h5>ÖNGÖRÜLEMEYEN SEBEPLERLE ÜRÜN SÜRESİNDE TESLİM EDİLEMEZ İSE</h5>
          Satıcı’nın öngöremeyeceği mücbir sebepler oluşursa ve ürün süresinde
          teslim edilemez ise, durum Alıcı’ya bildirilir. Alıcı, siparişin
          iptalini, ürünün benzeri ile değiştirilmesini veya engel ortadan
          kalkana dek teslimatın ertelenmesini talep edebilir. Alıcı siparişi
          iptal ederse; ödemeyi nakit ile yapmış ise iptalinden itibaren 14 gün
          içinde kendisine nakden bu ücret ödenir. Alıcı, ödemeyi kredi kartı
          ile yapmış ise ve iptal ederse, bu iptalden itibaren yine 14 gün
          içinde ürün bedeli bankaya iade edilir, ancak bankanın alıcının
          hesabına 2-3 hafta içerisinde aktarması olasıdır.
          <h5>ALICININ ÜRÜNÜ KONTROL ETME YÜKÜMLÜLÜĞÜ:</h5>
          Alıcı, sözleşme konusu mal/hizmeti teslim almadan önce muayene edecek;
          ezik, kırık, ambalajı yırtılmış vb. hasarlı ve ayıplı mal/hizmeti
          kargo şirketinden teslim almayacaktır. Teslim alınan mal/hizmetin
          hasarsız ve sağlam olduğu kabul edilecektir. ALICI , Teslimden sonra
          mal/hizmeti özenle korunmak zorundadır. Cayma hakkı kullanılacaksa
          mal/hizmet kullanılmamalıdır. Ürünle birlikte Fatura da iade
          edilmelidir.
          <h5>CAYMA HAKKI</h5>
          ALICI; satın aldığı ürünün kendisine veya gösterdiği adresteki
          kişi/kuruluşa teslim tarihinden itibaren 14 (on dört) gün içerisinde,
          SATICI’ya aşağıdaki iletişim bilgileri üzerinden bildirmek şartıyla
          hiçbir hukuki ve cezai sorumluluk üstlenmeksizin ve hiçbir gerekçe
          göstermeksizin malı reddederek sözleşmeden cayma hakkını kullanabilir.
          <h5>CAYMA HAKKININ SÜRESİ</h5>
          Alıcı, satın aldığı eğer bir hizmet ise, bu 14 günlük süre sözleşmenin
          imzalandığı tarihten itibaren başlar. Cayma hakkı süresi sona ermeden
          önce, tüketicinin onayı ile hizmetin ifasına başlanan hizmet
          sözleşmelerinde cayma hakkı kullanılamaz.
          <br />
          <br />
          Cayma hakkının kullanılması için 14 (ondört) günlük süre içinde
          SATICI' ya iadeli taahhütlü posta, faks veya eposta ile yazılı
          bildirimde bulunulması ve ürünün işbu sözleşmede düzenlenen "Cayma
          Hakkı Kullanılamayacak Ürünler" hükümleri çerçevesinde kullanılmamış
          olması şarttır.
          <h5>CAYMA HAKKININ KULLANIMI</h5>
          3. kişiye veya ALICI’ ya teslim edilen ürünün faturası, (İade edilmek
          istenen ürünün faturası kurumsal ise, iade ederken kurumun düzenlemiş
          olduğu iade faturası ile birlikte gönderilmesi gerekmektedir. Faturası
          kurumlar adına düzenlenen sipariş iadeleri İADE FATURASI kesilmediği
          takdirde tamamlanamayacaktır.)
          <br />
          <br />
          İade formu, İade edilecek ürünlerin kutusu, ambalajı, varsa standart
          aksesuarları ile birlikte eksiksiz ve hasarsız olarak teslim edilmesi
          gerekmektedir.
          <h5>İADE KOŞULLARI</h5>
          SATICI, cayma bildiriminin kendisine ulaşmasından itibaren en geç 10
          günlük süre içerisinde toplam bedeli ve ALICI’yı borç altına sokan
          belgeleri ALICI’ ya iade etmek ve 20 günlük süre içerisinde malı iade
          almakla yükümlüdür.
          <br />
          <br />
          ALICI’ nın kusurundan kaynaklanan bir nedenle malın değerinde bir
          azalma olursa veya iade imkânsızlaşırsa ALICI kusuru oranında SATICI’
          nın zararlarını tazmin etmekle yükümlüdür. Ancak cayma hakkı süresi
          içinde malın veya ürünün usulüne uygun kullanılması sebebiyle meydana
          gelen değişiklik ve bozulmalardan ALICI sorumlu değildir.
          <br />
          <br />
          Cayma hakkının kullanılması nedeniyle SATICI tarafından düzenlenen
          kampanya limit tutarının altına düşülmesi halinde kampanya kapsamında
          faydalanılan indirim miktarı iptal edilir.
          <h5>CAYMA HAKKI KULLANILAMAYACAK ÜRÜNLER</h5>
          ALICI’nın isteği veya açıkça kişisel ihtiyaçları doğrultusunda
          hazırlanan ve geri gönderilmeye müsait olmayan, tek kullanımlık
          ürünler, çabuk bozulma tehlikesi olan veya son kullanma tarihi geçme
          ihtimali olan mallar, ALICI’ya teslim edilmesinin ardından ALICI
          tarafından ambalajı açıldığı takdirde iade edilmesi sağlık ve hijyen
          açısından uygun olmayan ürünler, teslim edildikten sonra başka
          ürünlerle karışan ve doğası gereği ayrıştırılması mümkün olmayan
          ürünler, Abonelik sözleşmesi kapsamında sağlananlar dışında, yazılım
          programlarının, ambalajının ALICI tarafından açılmış olması halinde
          iadesi Yönetmelik gereği mümkün değildir. Ayrıca Cayma hakkı süresi
          sona ermeden önce, tüketicinin onayı ile ifasına başlanan hizmetlere
          ilişkin cayma hakkının kullanılması da Yönetmelik gereği mümkün
          değildir.
          <h5>TEMERRÜT HALİ VE HUKUKİ SONUÇLARI</h5>
          ALICI, ödeme işlemlerini kredi kartı ile yaptığı durumda temerrüde
          düştüğü takdirde, kart sahibi banka ile arasındaki kredi kartı
          sözleşmesi çerçevesinde faiz ödeyeceğini ve bankaya karşı sorumlu
          olacağını kabul, beyan ve taahhüt eder. Bu durumda ilgili banka hukuki
          yollara başvurabilir; doğacak masrafları ve vekâlet ücretini ALICI’dan
          talep edebilir ve her koşulda ALICI’nın borcundan dolayı temerrüde
          düşmesi halinde, ALICI, borcun gecikmeli ifasından dolayı SATICI’nın
          uğradığı zarar ve ziyanını ödeyeceğini kabul eder.
          <h5>ÖDEME VE TESLİMAT</h5>
          Sitemiz üzerinden kredi kartlarınız ile, Her türlü kredi kartınıza
          online tek ödeme ya da online taksit imkânlarından
          yararlanabilirsiniz. Online ödemelerinizde siparişiniz sonunda kredi
          kartınızdan tutar çekim işlemi gerçekleşecektir.
        </p>
      </DialogContentText>
    </Fragment>
  );
};

export default Iptalveiade;
