import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { get_ubitray, get_filtered_products } from "../actions/products";
import {
  add_item,
  get_items,
  get_total,
  get_item_total,
} from "../actions/cart";
import { get_categories } from "../actions/categories";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Grid } from "@mui/material";
import UbiTrayCard from "../components/UbiTrayCard";
import { Helmet } from "react-helmet";
import UbiTrayBanner from "../assets/images/UbiTrayBanner.jpg";

const UbiTray = ({
  categories,
  get_categories,
  products,
  get_ubitray,
  filtered_products,
  get_filtered_products,
  add_item,
  get_items,
  get_total,
  get_item_total,
}) => {
  const [redirect, setRedirect] = useState(false);
  const [filtered] = useState(false);

  useEffect(() => {
    get_categories();
    get_ubitray();
    window.scrollTo(0, 0);
  }, []);

  const showProducts = () => {
    let results = [];

    if (!filtered && products && products !== null && products !== undefined) {
      products.map((product, index) => {
        return results.push(
          <div key={index}>
            <UbiTrayCard
              product={product}
              add_item={add_item}
              get_items={get_items}
              get_total={get_total}
              get_item_total={get_item_total}
              setRedirect={setRedirect}
            />
          </div>
        );
      });
    }

    return results;
  };

  if (redirect) return <Redirect to="/cart-or-continue-shopping" />;

  let width = window.innerWidth;

  if (width > 800) {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="UbiTray ile yeni nesil tohumlar ile tanışın ekme, biçme gibi konularla uğraşmadan sebze ve meyve üretin"
          />
          <title>UbiTray Liste - Ubicro</title>
          <link rel="canonical" href="https://www.ubicro.com/ubitray" />
        </Helmet>
        <Header />
        <Grid container direction="column" style={{ backgroundColor: "#fff" }}>
          <Grid
            item
            container
            direction="row"
            style={{ maxWidth: 1400, alignSelf: "center" }}
          >
            <Grid
              lg={12}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              style={{ marginTop: 40 }}
            >
              <img
                src={UbiTrayBanner}
                alt="UbiTray Banenr"
                style={{ width: "90%", maxWidth: 1200 }}
              ></img>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              style={{ marginTop: 50, marginBottom: 100 }}
            >
              {showProducts()}
            </Grid>
          </Grid>
        </Grid>
        <Footer />
      </div>
    );
  } else {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="UbiTray ile yeni nesil tohumlar ile tanışın ekme, biçme gibi konularla uğraşmadan sebze ve meyve üretin"
          />
          <title>UbiTray Liste - Ubicro</title>
          <link rel="canonical" href="https://www.ubicro.com/ubitray" />
        </Helmet>
        <Header />
        <Grid container direction="column" style={{ backgroundColor: "#fff" }}>
          <Grid item container direction="row" style={{ padding: 10 }}>
            <Grid
              lg={12}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              style={{ marginTop: 40 }}
            >
              <img
                src={UbiTrayBanner}
                alt="UbiTray Banenr"
                style={{ width: "90%", maxWidth: 1200 }}
              ></img>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              style={{ marginTop: 50, marginBottom: 100 }}
            >
              {showProducts()}
            </Grid>
          </Grid>
        </Grid>
        <Footer />
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  categories: state.categories.categories,
  products: state.products.products,
  filtered_products: state.products.filtered_products,
});

export default connect(mapStateToProps, {
  get_categories,
  get_ubitray,
  get_filtered_products,
  add_item,
  get_items,
  get_total,
  get_item_total,
})(UbiTray);
