import React, { useEffect, useState } from "react";
import axios from "axios";
import { Grid, Typography, useMediaQuery, useTheme, Box } from "@mui/material";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import Footer from "../components/Footer";
import UbicroBackgroundLogo from "../assets/images/ubicro_logo_background.jpg";

const Hakkimizda = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [aboutBanners, setAboutBanners] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/homepage/banners/?page=about`)
      .then((res) => {
        setAboutBanners(res.data);
      })
      .catch((error) => {
        console.error("Error fetching about banners:", error);
      });
  }, []);

  const firstBanner = aboutBanners.length > 0 ? aboutBanners[0] : null;
  const secondBanner = aboutBanners.length > 1 ? aboutBanners[1] : null;

  return (
    <Box sx={{ width: "100%" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Hakkımızda sayfası" />
        <title>Hakkımızda - Ubicro</title>
        <link rel="canonical" href="https://www.ubicro.com/hakkimizda" />
      </Helmet>

      <Header />

      {/* Main container for the page content */}
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        direction="column"
        sx={{
          mt: { xs: 4, md: 12 },
          width: "100%",
          maxWidth: "1800px",
          margin: "0 auto",
        }}
      >
        {/* Top Title */}
        <Typography
          variant="h4"
          sx={{
            width: "100%",
            maxWidth: 800,
            textAlign: "center",
            mb: 4,
            px: 2,
          }}
        >
          <span style={{ color: "#15922E" }}>Taze</span> ve{" "}
          <span style={{ color: "#15922E" }}>sağlıklı</span> üretimle yanı
          başınızda, <span style={{ color: "#15922E" }}>sürdürülebilir</span>{" "}
          alanlar tasarlayarak daha{" "}
          <span style={{ color: "#15922E" }}>yeşil</span> bir geleceğe birlikte
          adım atalım!
        </Typography>

        {/* First (top) banner/image */}
        <Box
          component="img"
          src={firstBanner?.image_file}
          alt="HakkimizdaImage"
          sx={{
            width: "100%",
            maxWidth: 1500,
            height: "auto",
            mt: { xs: 4, md: 10 },
          }}
        />
      </Grid>

      {/* 
        Vision Section
        Full-width Box with background + 
        an inner container for text.
      */}
      <Box
        sx={{
          width: "100%",
          // Decide whether to use "cover" or "contain":
          backgroundSize: "cover",
          // "cover" can crop; "contain" ensures full image is shown (may show empty space).
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${UbicroBackgroundLogo})`,
          py: { xs: 4, md: 8 },
          // Some vertical padding so the background is visible
        }}
      >
        {/* Inner container that holds the text, centered with max width */}
        <Grid
          container
          alignItems="center"
          direction="column"
          sx={{
            width: { xs: "90%", md: "70%" },
            minHeight: { xs: 300, md: 500 },
            margin: "0 auto",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              mt: { xs: 4, md: 10 },
              color: "#15922E",
              textAlign: "center",
            }}
          >
            Vizyonumuz
          </Typography>

          <Typography
            variant={isSmallScreen ? "body1" : "h5"}
            sx={{
              mt: 4,
              textAlign: "center",
              maxWidth: 900,
            }}
          >
            <span style={{ color: "#15922E" }}>Ubicro</span> insanların her
            zaman ve her yerde, taze, pestisitsiz ve yüksek besin değerine sahip
            mahsullerin yetiştirilebilmesini amaçlamaktadır. İnsan gücüne gerek
            kalmadan IoT teknolojisi kullanarak topraksız tarım yapan
            teknolojiler geliştiriyor.
          </Typography>

          <Typography
            variant="h4"
            sx={{
              mt: { xs: 6, md: 10 },
              color: "#15922E",
              textAlign: "center",
            }}
          >
            Takımımız
          </Typography>
        </Grid>
      </Box>

      {/* Second Image/Banner */}
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        direction="column"
        sx={{
          width: "100%",
          maxWidth: "1800px",
          margin: "0 auto",
        }}
      >
        <Box
          component="img"
          src={secondBanner?.image_file}
          alt="UbicroTeam"
          sx={{
            width: "100%",
            height: "auto",
            maxWidth: 1500,
            mb: 4,
          }}
        />
      </Grid>

      {/* 
        Blog / YouTube Section: 
        Keep the green background 100% wide 
        while the videos are centered at a max width. 
      */}
      <Box
        sx={{
          width: "100%",
          bgcolor: "#15922E",
          py: 6,
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="flex-start"
          direction="column"
          sx={{
            width: "90%",
            maxWidth: 1400,
            mx: "auto",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              mb: { xs: 2, md: 6 },
              mt: { xs: 4, md: 10 },
              color: "#fff",
            }}
          >
            Blog
          </Typography>

          {/* Videos row */}
          <Grid
            container
            spacing={4}
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid item xs={12} md="auto">
              <Typography
                variant="body1"
                sx={{
                  color: "#000",
                  backgroundColor: "#FFC94B",
                  width: 80,
                  textAlign: "center",
                }}
              >
                Youtube
              </Typography>
              <Box
                component="iframe"
                width={isSmallScreen ? "100%" : 560}
                height={isSmallScreen ? "220" : 315}
                src="https://www.youtube.com/embed/sKVdIStGx6U"
                title="YouTube video player"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                sx={{
                  margin: 1,
                  borderRadius: 2,
                  display: "block",
                  maxWidth: isSmallScreen ? "100%" : "none",
                }}
              />
            </Grid>

            <Grid item xs={12} md="auto">
              <Typography
                variant="body1"
                sx={{
                  color: "#000",
                  backgroundColor: "#FFC94B",
                  width: 80,
                  textAlign: "center",
                }}
              >
                Youtube
              </Typography>
              <Box
                component="iframe"
                width={isSmallScreen ? "100%" : 560}
                height={isSmallScreen ? "220" : 315}
                src="https://www.youtube.com/embed/1BzUVXZ4tEc"
                title="YouTube video player"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                sx={{
                  margin: 1,
                  borderRadius: 2,
                  display: "block",
                  maxWidth: isSmallScreen ? "100%" : "none",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {/* Bottom Spacing */}
      <Box sx={{ height: 100, width: "100%" }} />

      <Footer />
    </Box>
  );
};

export default Hakkimizda;
