import React, { useEffect, useState } from "react";
import axios from "axios";
import { Grid, Box } from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import UbicroPlus from "../assets/images/UbicroPlus.jpg";
import BannerMedia from "../components/BannerMedia";

const Anasayfa = () => {
  const [largeBanners, setLargeBanners] = useState([]);
  const [smallBanners, setSmallBanners] = useState([]);
  const [middleBanners, setMiddleBanners] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/homepage/banners/?page=home`)
      .then((response) => {
        const allBanners = response.data;
        const large = allBanners.filter((b) => b.banner_type === "large");
        const horizontal = allBanners.filter(
          (b) => b.banner_type === "horizontal"
        );
        const small = allBanners.filter((b) => b.banner_type === "small");
        setLargeBanners(large);
        setMiddleBanners(horizontal);
        setSmallBanners(small);
      })
      .catch((error) => {
        console.error("Error fetching banners:", error);
      });
  }, []);

  // Common sizing styles for our banners
  const commonStyles = {
    width: "100%",
    maxWidth: 1200,
    height: "auto",
  };

  /**
   * If the banner has a link_url, we wrap it in an <a>.
   */
  const Wrapper = ({ banner, children }) => {
    if (banner.link_url) {
      return (
        <a href={banner.link_url} style={{ textDecoration: "none" }}>
          {children}
        </a>
      );
    }
    return children;
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Tarımda Yeni Çağın Başlangıcı" />
        <title>Ubicro</title>
        <link rel="canonical" href="https://www.ubicro.com" />
      </Helmet>
      <Header />

      {/* LARGE BANNERS */}
      {largeBanners.map((banner, index) => (
        <Grid
          key={index}
          container
          justifyContent="center"
          alignItems="center"
          style={{ backgroundColor: banner.background_color }}
        >
          <Wrapper banner={banner}>
            <BannerMedia banner={banner} commonStyles={commonStyles} />
          </Wrapper>
        </Grid>
      ))}

      {/* HORIZONTAL SCROLL SECTION (MIDDLE BANNERS) */}
      <Box
        sx={{
          overflowX: "auto",
          whiteSpace: "nowrap",
          bgcolor: "#fff",
          py: 2,
        }}
      >
        {middleBanners.map((banner, index) => (
          <Grid
            key={index}
            sx={{
              display: "inline-block",
              width: "40%",
              minWidth: 400,
              maxWidth: 800,
              marginLeft: 1,
              boxSizing: "border-box",
              verticalAlign: "top",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Wrapper banner={banner}>
              <BannerMedia
                banner={banner}
                commonStyles={{ width: "100%", height: "auto" }}
              />
            </Wrapper>
          </Grid>
        ))}
      </Box>

      {/* SMALL BANNERS GRID */}
      {smallBanners.length > 0 && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{
            backgroundColor: "#fff",
            padding: "20px 0",
          }}
        >
          {smallBanners.map((banner, index) => (
            <Grid
              key={index}
              item
              xs={12}
              sm={6}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid
                container
                style={{
                  backgroundColor: "#F8F8F8",
                  borderRadius: 10,
                  width: "99%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 10,
                }}
              >
                <Wrapper banner={banner}>
                  <BannerMedia
                    banner={banner}
                    commonStyles={{ width: "100%", height: "auto" }}
                  />
                </Wrapper>
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}

      {/* Advantages Section  */}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ backgroundColor: "#F8F8F8" }}
      >
        <img
          src={UbicroPlus}
          alt="Banner"
          style={{
            width: "100%",
            maxWidth: 1000,
            height: "auto",
          }}
        />
      </Grid>
      <Footer />
    </div>
  );
};

export default Anasayfa;
