import React, { useState, useEffect, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { get_product, get_related_products } from "../actions/products";
import {
  add_item,
  get_items,
  get_total,
  get_item_total,
} from "../actions/cart";
import ProductDetailCard from "../components/ProductDetailCard";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Grid, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import UrunBant from "../components/UrunBant";
import RelatedProductsCard from "../components/RelatedProductsCard";

const ProductDetail = ({
  match,
  product,
  get_product,
  add_item,
  get_items,
  get_total,
  get_item_total,
  get_related_products,
  related_products,
}) => {
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const productId = match.params.id;

    get_product(productId);
    get_related_products(productId);
  }, [match.params.id]);

  const getRelatedProducts = () => {
    if (
      related_products &&
      related_products !== null &&
      related_products !== undefined &&
      related_products.length !== 0
    ) {
      return related_products.map((product, index) => (
        <div key={index} className="col-4">
          <RelatedProductsCard
            product={product}
            add_item={add_item}
            get_items={get_items}
            get_total={get_total}
            get_item_total={get_item_total}
          />
        </div>
      ));
    }
  };

  if (redirect) return <Redirect to="/cart-or-continue-shopping" />;
  let width = window.innerWidth;

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {`${
            product &&
            product !== null &&
            product !== undefined &&
            product.name &&
            product.name !== null &&
            product.name !== undefined
              ? product.name
              : ""
          }`}{" "}
          Detay - Ubicro
        </title>
      </Helmet>
      <Header />
      <UrunBant />
      <Grid container justifyContent="center" alignItems="center">
        <ProductDetailCard
          product={product}
          add_item={add_item}
          get_items={get_items}
          get_total={get_total}
          get_item_total={get_item_total}
          setRedirect={setRedirect}
        />
        {product && product.category === 1 ? (
          <>
            {width > 800 ? (
              <Typography
                style={{
                  marginBottom: 80,
                  fontSize: "4rem",
                  fontWeight: "bold",
                  background:
                    "linear-gradient(90deg, #69BFF9 0%, #2BA24C 35%, #B7CDE8 74%, #15922E 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  display: "inline-block",
                }}
                variant="h2"
              >
                Hangi UbiHouse size göre
              </Typography>
            ) : (
              <Typography
                style={{
                  marginBottom: 80,
                  fontSize: "2rem",
                  fontWeight: "bold",
                  background:
                    "linear-gradient(90deg, #69BFF9 0%, #2BA24C 35%, #B7CDE8 74%, #15922E 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  display: "inline-block",
                }}
                variant="h2"
              >
                Hangi UbiHouse size göre
              </Typography>
            )}

            <Grid container justifyContent="center">
              {getRelatedProducts()}
            </Grid>
          </>
        ) : (
          <></>
        )}
      </Grid>
      <Grid style={{ marginTop: 150 }} />
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  product: state.products.product,
  related_products: state.products.related_products,
});

export default connect(mapStateToProps, {
  get_product,
  get_related_products,
  add_item,
  get_items,
  get_total,
  get_item_total,
})(ProductDetail);
