import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { get_ubihouse, get_filtered_products } from "../actions/products";
import {
  add_item,
  get_items,
  get_total,
  get_item_total,
} from "../actions/cart";
import { get_categories } from "../actions/categories";
import Card from "../components/Card";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Grid, Typography, CircularProgress } from "@mui/material";
import { Helmet } from "react-helmet";

const UbiHouseListe = ({
  categories,
  get_categories,
  products,
  get_ubihouse,
  filtered_products,
  get_filtered_products,
  add_item,
  get_items,
  get_total,
  get_item_total,
}) => {
  const [redirect, setRedirect] = useState(false);
  const [filtered] = useState(false);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true before fetching
      await get_categories();
      await get_ubihouse();
      setLoading(false); // Set loading to false after fetching
    };
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  const showProducts = () => {
    if (loading) {
      // Show a loading spinner while data is being fetched
      return (
        <Grid container justifyContent="center" alignItems="center">
          <CircularProgress style={{ marginTop: 20, marginBottom: 800 }} />
        </Grid>
      );
    }

    const productList =
      filtered && filtered_products ? filtered_products : products;

    if (!productList || productList.length === 0) {
      return (
        <Typography variant="h6" style={{ marginTop: 20 }}>
          Ürün bulunamadı.
        </Typography>
      );
    }

    return (
      <Grid container spacing={1} justifyContent="center">
        {productList.map((product, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            key={index}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Card
              product={product}
              add_item={add_item}
              get_items={get_items}
              get_total={get_total}
              get_item_total={get_item_total}
              setRedirect={setRedirect}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  if (redirect) return <Redirect to="/cart-or-continue-shopping" />;

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Evinizde üretim yapmaya başlayın taze, kimyasalsız, az enerji tüketimi ve çevreye zarar vermeden"
        />
        <title>UbiHouse Liste - Ubicro</title>
        <link rel="canonical" href="https://www.ubicro.com/ubihouse" />
      </Helmet>
      <Header />

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Typography
          style={{
            marginBottom: 80,
            marginTop: 50,
            fontSize: "3rem",
            fontWeight: "500",
            background:
              "linear-gradient(90deg, #69BFF9 0%, #2BA24C 35%, #B7CDE8 74%, #15922E 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            display: "inline-block",
          }}
          variant="h2"
        >
          Tüm Modeller
        </Typography>
        <Grid container justifyContent="center" alignItems="center">
          {showProducts()}
        </Grid>
      </Grid>

      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  categories: state.categories.categories,
  products: state.products.products,
  filtered_products: state.products.filtered_products,
});

export default connect(mapStateToProps, {
  get_categories,
  get_ubihouse,
  get_filtered_products,
  add_item,
  get_items,
  get_total,
  get_item_total,
})(UbiHouseListe);
