import React from "react";
import {
  Toolbar,
  Typography,
  Button,
  Grid,
  Hidden,
  AppBar,
  ListSubheader,
  Avatar,
  Badge,
  IconButton,
  ListItemButton,
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemText,
  ListItemIcon,
  Fade,
  Paper,
  Popper,
} from "@mui/material";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logout, login } from "../actions/auth";

// Image Imports
import UbicroLogo from "../assets/images/logo.png";
import UbiHouseIcon from "../assets/images/ubihouseIcon.png";

// Icon Imports
import MenuIcon from "@mui/icons-material/Menu";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";

const Header = ({ isAuthenticated, logout, total_items }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [openPopper, setOpenPopper] = React.useState(false);
  const [placement, setPlacement] = React.useState();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpenPopper((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  return (
    <div>
      <div>
        <AppBar
          position="static"
          elevation={0}
          style={{ padding: 10, backgroundColor: "#fff" }}
        >
          <Toolbar>
            <Grid item container alignItems="center" style={{ maxWidth: 800 }}>
              <IconButton href="/" style={{ marginLeft: 30, borderRadius: 10 }}>
                <img src={UbicroLogo} alt="logo" className="logo" />
                <Typography
                  style={{
                    fontSize: 24,
                    marginLeft: 20,
                    fontWeight: 200,
                  }}
                >
                  Ubicro
                </Typography>
              </IconButton>
            </Grid>
            <Hidden lgDown>
              <Grid item container spacing={5}>
                <Grid item>
                  <Button
                    href="/ubihouse"
                    color="inherit"
                    style={{
                      fontSize: 18,
                      textTransform: "none",
                      color: "#343434",
                    }}
                  >
                    UbiHouse
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    href="/ubitray"
                    color="inherit"
                    style={{
                      fontSize: 18,
                      textTransform: "none",
                      color: "#343434",
                    }}
                  >
                    UbiTray
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    href="/product/ubienergy"
                    color="inherit"
                    style={{
                      fontSize: 18,
                      textTransform: "none",
                      color: "#343434",
                    }}
                  >
                    UbiEnergy
                  </Button>
                </Grid>
              </Grid>
            </Hidden>

            <Hidden lgUp>
              <IconButton
                color="inherit"
                href="/sepetim"
                style={{ right: -20 }}
              >
                <Badge badgeContent={total_items} color="error">
                  <ShoppingCartIcon style={{ color: "#343434" }} />
                </Badge>
              </IconButton>
            </Hidden>

            <Hidden lgDown>
              <Button
                variant="outlined"
                color="inherit"
                style={{
                  fontSize: 12,
                  padding: 15,
                  marginRight: 30,
                  borderColor: "#DEDEE5",
                  width: 280,
                  height: 60,
                  textTransform: "none",
                  color: "#343434",
                }}
                href="/sepetim"
                startIcon={
                  <Badge badgeContent={total_items} color="error">
                    <ShoppingCartIcon />
                  </Badge>
                }
              >
                <Grid>
                  <Typography variant="body1">Sepetim</Typography>
                </Grid>
              </Button>
            </Hidden>

            {!isAuthenticated && (
              <React.Fragment>
                <Hidden lgDown>
                  <Button
                    variant="contained"
                    color="inherit"
                    style={{
                      fontSize: 12,
                      width: 300,
                      height: 60,
                      textTransform: "none",
                      backgroundColor: "#fff",
                    }}
                    startIcon={
                      <Avatar
                        style={{
                          width: 26,
                          height: 26,
                        }}
                      >
                        <PersonIcon fontSize="small" />
                      </Avatar>
                    }
                    href="/Login"
                  >
                    <Grid>
                      <Grid>
                        <Typography
                          variant="body1"
                          style={{ color: "#343434" }}
                        >
                          Giriş Yap
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            fontSize: 12,
                            color: "grey",
                          }}
                        >
                          veya üye ol
                        </Typography>
                      </Grid>
                    </Grid>
                  </Button>
                </Hidden>
              </React.Fragment>
            )}

            {isAuthenticated && (
              <React.Fragment>
                <Hidden lgDown>
                  <Button
                    variant="contained"
                    color="inherit"
                    style={{
                      fontSize: 12,
                      width: 300,
                      height: 60,
                      textTransform: "lowercase",
                      backgroundColor: "#fff",
                    }}
                    startIcon={
                      <AccountCircleIcon style={{ color: "#343434" }} />
                    }
                    onClick={handleClick("bottom")}
                  >
                    <Grid>
                      <Typography
                        style={{
                          fontSize: 14,
                          color: "#343434",
                          textTransform: "capitalize",
                        }}
                      >
                        Hesabım
                      </Typography>
                    </Grid>
                  </Button>
                </Hidden>

                <Popper
                  open={openPopper}
                  anchorEl={anchorEl}
                  placement={placement}
                  onClose={handleDrawerClose}
                  transition
                >
                  {({ TransitionProps }) => (
                    <Fade
                      {...TransitionProps}
                      timeout={600}
                      style={{
                        marginTop: 5,
                        marginRight: 35,
                      }}
                    >
                      <Paper>
                        <List>
                          <ListItemButton component="a" href="/dashboard">
                            <ListItemIcon>
                              <ManageAccountsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Kullanıcı Paneli" />
                          </ListItemButton>
                          <ListItemButton
                            onClick={logout}
                            component="a"
                            href="/"
                          >
                            <ListItemIcon>
                              <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText primary="Çıkış Yap" />
                          </ListItemButton>
                        </List>
                      </Paper>
                    </Fade>
                  )}
                </Popper>
              </React.Fragment>
            )}

            <Hidden lgUp>
              <Toolbar style={{ right: -20 }}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                >
                  <MenuIcon fontSize="large" style={{ color: "#343434" }} />
                </IconButton>
              </Toolbar>
            </Hidden>

            <Drawer anchor="right" open={open} onClose={handleDrawerClose}>
              <List subheader={<ListSubheader>Ürünler</ListSubheader>}>
                <ListItemButton component={Link} to="/ubihouse">
                  <ListItemIcon style={{ marginLeft: 10 }}>
                    {" "}
                    <img
                      src={UbiHouseIcon}
                      alt="logo"
                      className="logo"
                      style={{ height: 30, borderRadius: 5 }}
                    />
                  </ListItemIcon>
                  <ListItemText>UbiHouse</ListItemText>
                </ListItemButton>
                <ListItemButton component={Link} to="/ubitray">
                  <ListItemIcon style={{ marginLeft: 10 }}>
                    {" "}
                    <CalendarViewMonthIcon />
                  </ListItemIcon>
                  <ListItemText>UbiTray</ListItemText>
                </ListItemButton>
                <ListItemButton component={Link} to="/product/ubienergy">
                  <ListItemIcon style={{ marginLeft: 10 }}>
                    {" "}
                    <FlashOnIcon />
                  </ListItemIcon>
                  <ListItemText>UbiEnergy</ListItemText>
                </ListItemButton>
              </List>
              <Divider />
              <List subheader={<ListSubheader>Kullanıcı</ListSubheader>}>
                {isAuthenticated && (
                  <div>
                    <ListItem button component={Link} to="/dashboard">
                      <ListItemIcon style={{ marginLeft: 10 }}>
                        {" "}
                        <ManageAccountsIcon />{" "}
                      </ListItemIcon>
                      <ListItemText style={{ marginRight: 10 }}>
                        Kullanıcı Paneli
                      </ListItemText>
                    </ListItem>
                    <ListItem button component={Link} to="/" onClick={logout}>
                      <ListItemIcon style={{ marginLeft: 10 }}>
                        {" "}
                        <LogoutIcon />{" "}
                      </ListItemIcon>
                      <ListItemText style={{ marginRight: 10 }}>
                        Hesaptan Çık
                      </ListItemText>
                    </ListItem>
                  </div>
                )}
                {!isAuthenticated && (
                  <div>
                    <ListItem button component={Link} to="/Login">
                      <ListItemIcon style={{ marginLeft: 10 }}>
                        {" "}
                        <AccountCircleIcon />{" "}
                      </ListItemIcon>
                      <ListItemText style={{ marginRight: 10 }}>
                        Giriş Yap
                      </ListItemText>
                    </ListItem>
                    <ListItem button component={Link} to="/SignUp">
                      <ListItemIcon style={{ marginLeft: 10 }}>
                        {" "}
                        <PersonAddIcon />{" "}
                      </ListItemIcon>
                      <ListItemText style={{ marginRight: 10 }}>
                        Üye Ol
                      </ListItemText>
                    </ListItem>
                  </div>
                )}
              </List>
            </Drawer>
          </Toolbar>
        </AppBar>
        <Divider variant="fullWidth" light />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  total_items: state.cart.total_items,
});

export default connect(mapStateToProps, { login, logout })(Header);
